import axios from "axios";
import { Toast } from "vant";
import router from '../router/index';
import store from '../store/index'

axios.defaults.baseURL="https://ddsite.xyz:8081"
// axios.defaults.baseURL="http://localhost:8081"

//配置前置拦截
axios.interceptors.request.use(config => { 
    return config
})

//配置后置拦截

axios.interceptors.response.use(response=>{
    let res = response.data;

    if(res.code === 200) {
        return response
    }else{
        Toast(response.data.msg+'(⊙o⊙)？')
        return Promise.reject(response.data.msg)
    }
}, error=>{
    if(error.response.status === 401){
        store.commit("REMOVE_INFO")
        router.push("/login")
    }else if(error.response.status === 400){

    }
    else{
        Toast(error.response.data.msg+"┗|｀O′|┛")
    }
    return Promise.reject(response.data.msg)
})