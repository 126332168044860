// import { get } from 'core-js/core/dict'
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'


Vue.use(Vuex)

//准备actions：响应组件中的动作
const actions = {
    //用户注册

}

//准备mutations：用于操作数据（state）
const mutations = {
    SET_TOKEN: (state,token) => {
        state.token = token
        localStorage.setItem("token", token)
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
        localStorage.setItem("userInfo", JSON.stringify(userInfo))
    },
    REMOVE_INFO: (state) => {
        state.token = ""
        state.userInfo = {}
        localStorage.setItem("token", "")
        localStorage.setItem("userInfo", JSON.stringify(""))
    },

}

// 准备state：用于存储数据 
const state = {
    token: localStorage.getItem("token"),
    userInfo: JSON.parse(localStorage.getItem("userInfo"))
}

//准备getters: 用于将state的数据进行加工
const getters ={
    getUser: state =>{
        return state.userInfo
    }
    
}

//创建store 并 暴露store
export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters,
})