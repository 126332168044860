//引入Vue
import Vue from 'vue'
//引入App
import App from './App.vue'
//引入store
import store from './store/index'
//引入VueRouter
import router from './router'



import './assets/style/icon1/iconfont.css'
//引入vant-ui
import { Image as VanImage } from 'vant';
import {Button,Lazyload,Field,Icon,Tab, Tabs ,Overlay ,Search, Popup, Popover,Loading,Uploader,Toast,Cell, CellGroup,ImagePreview,NavBar,Form,Picker,DatetimePicker,Tag,Divider,Skeleton, List,PullRefresh} from 'vant'
//引入axios
import axios from 'axios'
import "./axios/axios"

Vue.prototype.$axios= axios

//关闭Vue的生产提示
Vue.config.productionTip = false
//应用插件
Vue.use(Lazyload);
Vue.use(Icon)
Vue.use(Overlay);
Vue.use(VanImage);
Vue.use(Loading);
Vue.use(Button)
Vue.use(Field)
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Search);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(ImagePreview);
Vue.use(NavBar);
Vue.use(Form);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(Skeleton);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Popover);
//创建vm
new Vue({
	el:'#app',
	render: h => h(App),
	store,
	router,
	//全局事件总线
	beforeCreate() {
		Vue.prototype.$bus = this
	},
})


