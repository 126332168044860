//该文件专门用于创建整个应用的路由器
import Vue from 'vue'
import VueRouter from "vue-router"


Vue.use(VueRouter)

export default new VueRouter({
    routes:[
        {
            path:'/home',
            name:'Home',
            component: () => import('@/views/Home'),
            
        },
        {
            path:'/register',
            name:'Register',
            component: () => import('@/views/Register')
        },
        {
            path:'/login',
            name:'login',
            component: () => import('@/views/Login.vue')
        },
        {
            path:'/main',
            name:'Main',
            component: () => import('@/views/Main')
        },

        {
            path:'/follower',
            name:'follower',
            component: () => import('@/views/Follower')
        },

        {
            path:'/personDetail/:userId',
            name:'personDetail',
            component: () => import('@/views/PersonDetail')
        },

        {
            path:'/search',
            name:'search',
            component: () => import('@/views/Search')
        },
        {
            path:'/markRoute',
            name:'markRoute',
            component: () => import('@/views/MarkRoute')
        },
        {
            path:'/setName',
            name:'setName',
            component: () => import('@/views/SetName')
        },
        {
            path:'/setAvatar',
            name:'setAvatar',
            component: () => import('@/views/SetAvatar')
        },
        {
            path:'/edit',
            name:'edit',
            component: () => import('@/views/Edit')
        },
        {
            path:'/upload',
            name:'upload',
            component: () => import('@/views/Upload')
        },
        {
            path:'/detail/:posterId',
            name:'detail',
            component: () => import('@/views/Detail')
        },
        {
            path:'/myCollection',
            name:'myCollection',
            component: () => import('@/views/MyCollection')
        },
        {
            path:'/myUpload',
            name:'myUpload',
            component: () => import('@/views/MyUpload')
        },

        {
            path:'/myMessage',
            name:'myMessage',
            component: () => import('@/views/MyMessage')
        },

        {
             path:'*',
             redirect:"/home"
        }
    ]
})
